export const baseFields = {
    start_date: {
        label: "Konsumtionsperiod från",
        errorMsg: "",
        identifier: "start_date",
        config: {},
        default: null,
        required: true,
    },
    end_date: {
        label: "Konsumtionsperiod till",
        errorMsg: "",
        identifier: "end_date",
        config: {},
        default: null,
        required: true,
    },
    film_code: {
        label: "Filmkoder",
        type: "input_multi",
        identifier: "film_code",
        config: {},
        required: true,
    },
    buyingAgencies: {
        label: "Byrå",
        type: "dropdown-select",
        identifier: "buyingAgencies",
        default: { label: "" },
        selectlistitems: [],
    },
    advertisers: {
        label: "Annonsör",
        type: "dropdown-select",
        identifier: "advertisers",
        default: { label: "" },
        selectlistitems: [],
    },
    products: {
        label: "Produkt",
        type: "dropdown-select",
        identifier: "products",
        default: { label: "" },
        selectlistitems: [],
    },
    spot_types: {
        label: "Filmtyp",
        type: "dropdown-checkboxes-spottypes",
        identifier: "spot_types",
        config: {
            options: [],
        },
        default: [],
    },
    target_group: {
        label: "Målgrupp",
        identifier: "target_group",
        type: "target_group",
        config: {},
    },
}

export const additionalFields = {}
