<template>
    <div>
        <table
            dense=""
            role="table"
            aria-busy="false"
            aria-colcount="15"
            aria-multiselectable="true"
            class="table b-table text-left table-striped table-hover table-bordered table-sm b-table-selectable b-table-select-multi b-table-selectable-no-click"
            id="__BVID__381"
            style="white-space: pre-line;"
        >
            <!----><!---->
            <thead role="rowgroup" class="thclass">
                <!---->
                <tr>
                    <th colspan="6" class="tvgreen text-center slimth">TV</th>
                    <th class="resultgrey text-center slimth">Länk</th>
                    <th colspan="6" class="onlineblue text-center slimth">Online</th>
                    <th colspan="4" class="resultgrey text-center slimth">
                        Reach (000) A3-99
                    </th>
                    <th colspan="4" class="resultgrey text-center slimth">
                        Rating (000) A3-99
                    </th>
                </tr>
                <tr role="row" class="">
                    <th role="columnheader" scope="col" tabindex="0" aria-colindex="2" aria-sort="none" class="b-table-sort-icon-left tvgreen">
                        <div>Datum</div>
                        <span class="sr-only"> (Click to sort Descending)</span>
                    </th>
                    <th role="columnheader" scope="col" tabindex="0" aria-colindex="3" aria-sort="none" class="b-table-sort-icon-left tvgreen">
                        <div>Starttid</div>
                        <span class="sr-only"> (Click to sort Descending)</span>
                    </th>
                    <th role="columnheader" scope="col" tabindex="0" aria-colindex="4" aria-sort="none" class="b-table-sort-icon-left tvgreen">
                        <div>Längd</div>
                        <span class="sr-only"> (Click to sort Descending)</span>
                    </th>
                    <th role="columnheader" scope="col" tabindex="0" aria-colindex="5" aria-sort="none" class="b-table-sort-icon-left tvgreen">
                        <div>Kanalnamn</div>
                        <span class="sr-only"> (Click to sort Descending)</span>
                    </th>
                    <th role="columnheader" scope="col" tabindex="0" aria-colindex="6" aria-sort="none" class="b-table-sort-icon-left tvgreen">
                        <div>Titel</div>
                        <span class="sr-only"> (Click to sort Descending)</span>
                    </th>
                    <th role="columnheader" scope="col" tabindex="0" aria-colindex="7" aria-sort="none" class="b-table-sort-icon-left tvgreen">
                        <div>Genre1</div>
                        <span class="sr-only"> (Click to sort Descending)</span>
                    </th>

                    <th role="columnheader" scope="col" tabindex="0" aria-colindex="7" aria-sort="none" class="resultgrey">
                        <div></div>
                    </th>

                    <th role="columnheader" scope="col" tabindex="0" aria-colindex="2" aria-sort="none" class="b-table-sort-icon-left onlineblue">
                        <div>Publicerat</div>
                        <span class="sr-only"> (Click to sort Descending)</span>
                    </th>
                    <th role="columnheader" scope="col" tabindex="0" aria-colindex="3" aria-sort="none" class="b-table-sort-icon-left onlineblue">
                        <div>Site</div>
                        <span class="sr-only"> (Click to sort Descending)</span>
                    </th>
                    <th role="columnheader" scope="col" tabindex="0" aria-colindex="4" aria-sort="none" class="b-table-sort-icon-left onlineblue">
                        <div>Titel</div>
                        <span class="sr-only"> (Click to sort Descending)</span>
                    </th>
                    <th role="columnheader" scope="col" tabindex="0" aria-colindex="5" aria-sort="none" class="b-table-sort-icon-left onlineblue">
                        <div>Episod</div>
                        <span class="sr-only"> (Click to sort Descending)</span>
                    </th>
                    <th role="columnheader" scope="col" tabindex="0" aria-colindex="6" aria-sort="none" class="b-table-sort-icon-left onlineblue">
                        <div>Längd</div>
                        <span class="sr-only"> (Click to sort Descending)</span>
                    </th>
                    <th role="columnheader" scope="col" tabindex="0" aria-colindex="7" aria-sort="none" class="b-table-sort-icon-left onlineblue">
                        <div>Genre1</div>
                        <span class="sr-only"> (Click to sort Descending)</span>
                    </th>

                    <th role="columnheader" scope="col" tabindex="0" aria-colindex="10" aria-sort="none" class="measure-header b-table-sort-icon-left resultgrey">
                        <div>TV, 1 min</div>
                        <span class="sr-only"> (Click to sort Descending)</span>
                    </th>
                    <th role="columnheader" scope="col" tabindex="0" aria-colindex="11" aria-sort="none" class="measure-header b-table-sort-icon-left resultgrey">
                        <div>(dbl täck)</div>
                        <span class="sr-only"> (Click to sort Descending)</span>
                    </th>
                    <th role="columnheader" scope="col" tabindex="0" aria-colindex="12" aria-sort="none" class="measure-header b-table-sort-icon-left resultgrey">
                        <div>Online, 15s</div>
                        <span class="sr-only"> (Click to sort Descending)</span>
                    </th>
                    <th role="columnheader" scope="col" tabindex="0" aria-colindex="13" aria-sort="none" class="measure-header b-table-sort-icon-left resultgrey">
                        <div>Total</div>
                        <span class="sr-only"> (Click to sort Descending)</span>
                    </th>

                    <th role="columnheader" scope="col" tabindex="0" aria-colindex="10" aria-sort="none" class="measure-header b-table-sort-icon-left resultgrey">
                        <div>TV, 1 min</div>
                        <span class="sr-only"> (Click to sort Descending)</span>
                    </th>
                    <th role="columnheader" scope="col" tabindex="0" aria-colindex="11" aria-sort="none" class="measure-header b-table-sort-icon-left resultgrey">
                        <div>(dbl täck)</div>
                        <span class="sr-only"> (Click to sort Descending)</span>
                    </th>
                    <th role="columnheader" scope="col" tabindex="0" aria-colindex="12" aria-sort="none" class="measure-header b-table-sort-icon-left resultgrey">
                        <div>Online, 15s</div>
                        <span class="sr-only"> (Click to sort Descending)</span>
                    </th>
                    <th role="columnheader" scope="col" tabindex="0" aria-colindex="13" aria-sort="none" class="measure-header b-table-sort-icon-left resultgrey">
                        <div>Total</div>
                        <span class="sr-only"> (Click to sort Descending)</span>
                    </th>
                </tr>
            </thead>
            <tbody role="rowgroup">
                <!---->

                <tr
                    role="row"
                    tabindex="0"
                    data-pk="7355468"
                    aria-selected="false"
                    style="border-bottom: #000 2px solid; background: rgba(250, 250, 250, .1); height: 4rem;"
                    id="__BVID__381__row_7355468"
                >
                    <td aria-colindex="2" role="cell" colspan="13" style="text-align:right; padding-top:1rem"><strong>SUMMA</strong></td>

                    <td aria-colindex="10" style="text-align:right; padding-top:1rem" role="cell" class="font-weight-bold">
                        1400
                    </td>
                    <td aria-colindex="11" style="text-align:right; padding-top:1rem" role="cell" class="font-weight-bold">
                        (300)
                    </td>
                    <td aria-colindex="12" style="text-align:right; padding-top:1rem" role="cell" class="font-weight-bold">
                        500
                    </td>
                    <td aria-colindex="13" style="text-align:right; padding-top:1rem" role="cell" class="font-weight-bold">
                        1600
                    </td>
                    <td aria-colindex="10" style="text-align:right; padding-top:1rem" role="cell" class="font-weight-bold">
                        2400
                    </td>
                    <td aria-colindex="11" style="text-align:right; padding-top:1rem" role="cell" class="font-weight-bold">
                        (600)
                    </td>
                    <td aria-colindex="12" style="text-align:right; padding-top:1rem" role="cell" class="font-weight-bold">
                        1500
                    </td>
                    <td aria-colindex="13" style="text-align:right; padding-top:1rem" role="cell" class="font-weight-bold">
                        3300
                    </td>
                </tr>

                <tr role="row" tabindex="0" data-pk="7355453" aria-selected="false" class="" id="__BVID__381__row_7355453">
                    <td aria-colindex="2" role="cell" class="text-nowrap">2022-10-19</td>
                    <td aria-colindex="3" role="cell" class="">19:55</td>
                    <td aria-colindex="4" role="cell" class="">4</td>
                    <td aria-colindex="5" role="cell" class="">SVT1</td>
                    <td aria-colindex="6" role="cell" class="">LOKALA NYHETER</td>
                    <td aria-colindex="7" role="cell" class="">Nyheter</td>
                    <td aria-colindex="7" role="cell" class="text-center">
                        <p class="h5 mb-2">
                            <b-icon icon="link"></b-icon>
                        </p>
                    </td>
                    <td aria-colindex="2" role="cell" class="text-nowrap">2022-10-19</td>
                    <td aria-colindex="3" role="cell" class="">SVT</td>
                    <td aria-colindex="4" role="cell" class="">Lokala Nyheter</td>
                    <td aria-colindex="5" role="cell" class="">2022-40</td>
                    <td aria-colindex="6" role="cell" class="">04:00</td>
                    <td aria-colindex="7" role="cell" class="">Nyheter</td>
                    <td aria-colindex="10" style="text-align:right" role="cell" class="">
                        469
                    </td>
                    <td aria-colindex="11" style="text-align:right" role="cell" class="">
                        (25)
                    </td>
                    <td aria-colindex="12" style="text-align:right" role="cell" class="">
                        244
                    </td>
                    <td aria-colindex="13" style="text-align:right" role="cell" class="">
                        688
                    </td>
                    <td aria-colindex="10" style="text-align:right" role="cell" class="">
                        569
                    </td>
                    <td aria-colindex="11" style="text-align:right" role="cell" class="">
                        (125)
                    </td>
                    <td aria-colindex="12" style="text-align:right" role="cell" class="">
                        344
                    </td>
                    <td aria-colindex="13" style="text-align:right" role="cell" class="">
                        788
                    </td>
                </tr>

                <tr role="row" tabindex="0" data-pk="7355455" aria-selected="false" class="" id="__BVID__381__row_7355455">
                    <td aria-colindex="2" role="cell" class="text-nowrap"></td>
                    <td aria-colindex="3" role="cell" class=""></td>
                    <td aria-colindex="4" role="cell" class=""></td>
                    <td aria-colindex="5" role="cell" class=""></td>
                    <td aria-colindex="6" role="cell" class=""></td>
                    <td aria-colindex="7" role="cell" class=""></td>
                    <td aria-colindex="7" role="cell" class="text-center"></td>
                    <td aria-colindex="2" role="cell" class="text-nowrap">2022-10-18</td>
                    <td aria-colindex="3" role="cell" class="">Expressen</td>
                    <td aria-colindex="4" role="cell" class="">Summerade nyheter</td>
                    <td aria-colindex="5" role="cell" class="">55</td>
                    <td aria-colindex="6" role="cell" class="">5:10</td>
                    <td aria-colindex="7" role="cell" class="">Nyheter</td>
                    <td aria-colindex="10" style="text-align:right" role="cell" class="">
                        0
                    </td>
                    <td aria-colindex="11" style="text-align:right" role="cell" class="">
                        (0)
                    </td>
                    <td aria-colindex="12" style="text-align:right" role="cell" class="">
                        323
                    </td>
                    <td aria-colindex="13" style="text-align:right" role="cell" class="">
                        323
                    </td>
                    <td aria-colindex="10" style="text-align:right" role="cell" class="">
                        0
                    </td>
                    <td aria-colindex="11" style="text-align:right" role="cell" class="">
                        (0)
                    </td>
                    <td aria-colindex="12" style="text-align:right" role="cell" class="">
                        424
                    </td>
                    <td aria-colindex="13" style="text-align:right" role="cell" class="">
                        424
                    </td>
                </tr>
                <tr role="row" tabindex="0" data-pk="7355455" aria-selected="false" class="" id="__BVID__381__row_7355455">
                    <td aria-colindex="2" role="cell" class="text-nowrap">2022-10-19</td>
                    <td aria-colindex="3" role="cell" class="">21:50</td>
                    <td aria-colindex="4" role="cell" class="">10</td>
                    <td aria-colindex="5" role="cell" class="">SVT1</td>
                    <td aria-colindex="6" role="cell" class="">LOKALA NYHETER</td>
                    <td aria-colindex="7" role="cell" class="">Nyheter</td>
                    <td aria-colindex="7" role="cell" class="text-center">
                        <p class="h5 mb-2">
                            <b-icon icon="link"></b-icon>
                        </p>
                    </td>
                    <td aria-colindex="2" role="cell" class="text-nowrap">2022-10-19</td>
                    <td aria-colindex="3" role="cell" class="">SVT</td>
                    <td aria-colindex="4" role="cell" class="">Lokala Nyheter</td>
                    <td aria-colindex="5" role="cell" class="">2022-40</td>
                    <td aria-colindex="6" role="cell" class="">09:50</td>
                    <td aria-colindex="7" role="cell" class="">Nyheter</td>
                    <td aria-colindex="10" style="text-align:right" role="cell" class="">
                        114
                    </td>
                    <td aria-colindex="11" style="text-align:right" role="cell" class="">
                        (17)
                    </td>
                    <td aria-colindex="12" style="text-align:right" role="cell" class="">
                        80
                    </td>
                    <td aria-colindex="13" style="text-align:right" role="cell" class="">
                        177
                    </td>
                    <td aria-colindex="10" style="text-align:right" role="cell" class="">
                        214
                    </td>
                    <td aria-colindex="11" style="text-align:right" role="cell" class="">
                        (117)
                    </td>
                    <td aria-colindex="12" style="text-align:right" role="cell" class="">
                        180
                    </td>
                    <td aria-colindex="13" style="text-align:right" role="cell" class="">
                        277
                    </td>
                </tr>
                <tr role="row" tabindex="0" data-pk="7355455" aria-selected="false" class="" id="__BVID__381__row_7355455">
                    <td aria-colindex="2" role="cell" class="text-nowrap">2022-10-19</td>
                    <td aria-colindex="3" role="cell" class="">22:10</td>
                    <td aria-colindex="4" role="cell" class="">10</td>
                    <td aria-colindex="5" role="cell" class="">TV3</td>
                    <td aria-colindex="6" role="cell" class="">Extra nyheter</td>
                    <td aria-colindex="7" role="cell" class="">Nyheter</td>
                    <td aria-colindex="7" role="cell" class="text-center"></td>
                    <td aria-colindex="2" role="cell" class="text-nowrap"></td>
                    <td aria-colindex="3" role="cell" class=""></td>
                    <td aria-colindex="4" role="cell" class=""></td>
                    <td aria-colindex="5" role="cell" class=""></td>
                    <td aria-colindex="6" role="cell" class=""></td>
                    <td aria-colindex="7" role="cell" class=""></td>
                    <td aria-colindex="10" style="text-align:right" role="cell" class="">
                        214
                    </td>
                    <td aria-colindex="11" style="text-align:right" role="cell" class="">
                        (0)
                    </td>
                    <td aria-colindex="12" style="text-align:right" role="cell" class="">
                        0
                    </td>
                    <td aria-colindex="13" style="text-align:right" role="cell" class="">
                        214
                    </td>
                    <td aria-colindex="10" style="text-align:right" role="cell" class="">
                        313
                    </td>
                    <td aria-colindex="11" style="text-align:right" role="cell" class="">
                        (0)
                    </td>
                    <td aria-colindex="12" style="text-align:right" role="cell" class="">
                        0
                    </td>
                    <td aria-colindex="13" style="text-align:right" role="cell" class="">
                        313
                    </td>
                </tr>
                <tr role="row" tabindex="0" data-pk="7355455" aria-selected="false" class="" id="__BVID__381__row_7355455">
                    <td aria-colindex="2" role="cell" class="text-nowrap">2022-10-19</td>
                    <td aria-colindex="3" role="cell" class="">23:10</td>
                    <td aria-colindex="4" role="cell" class="">10</td>
                    <td aria-colindex="5" role="cell" class="">TV3</td>
                    <td aria-colindex="6" role="cell" class="">Extra nyheter</td>
                    <td aria-colindex="7" role="cell" class="">Nyheter</td>
                    <td aria-colindex="7" role="cell" class="text-center"></td>
                    <td aria-colindex="2" role="cell" class="text-nowrap"></td>
                    <td aria-colindex="3" role="cell" class=""></td>
                    <td aria-colindex="4" role="cell" class=""></td>
                    <td aria-colindex="5" role="cell" class=""></td>
                    <td aria-colindex="6" role="cell" class=""></td>
                    <td aria-colindex="7" role="cell" class=""></td>
                    <td aria-colindex="10" style="text-align:right" role="cell" class="">
                        202
                    </td>
                    <td aria-colindex="11" style="text-align:right" role="cell" class="">
                        (0)
                    </td>
                    <td aria-colindex="12" style="text-align:right" role="cell" class="">
                        0
                    </td>
                    <td aria-colindex="13" style="text-align:right" role="cell" class="">
                        202
                    </td>
                    <td aria-colindex="10" style="text-align:right" role="cell" class="">
                        322
                    </td>
                    <td aria-colindex="11" style="text-align:right" role="cell" class="">
                        (0)
                    </td>
                    <td aria-colindex="12" style="text-align:right" role="cell" class="">
                        0
                    </td>
                    <td aria-colindex="13" style="text-align:right" role="cell" class="">
                        322
                    </td>
                </tr>
                <!----><!---->
            </tbody>
            <!---->
        </table>
    </div>
</template>

<script>
export default {
    name: "ResultTableSumCampaign",
    data() {
        return {}
    },
    props: {},
    mounted() {},

    beforeDestroy() {},
    computed: {},

    methods: {},
}
</script>

<style>
.thclass {
    position: sticky;
    top: 0;
    z-index: 2;
    color: #fff;
    background-color: #337ab7;
    border-color: #458dcb;
    font-size: 0.8rem;
    line-height: 2.5;
}

.tab-pane table tbody .sumrow {
    background-color: #efefef;
}

.sumrow .tdSummaryDataClass {
    border: 1px solid;
    padding: 0.1em 0.5em;
    line-height: 2;
}

.sumrow .columnBeforeSummaryData {
    border-right: 1px solid;
}

.tvgreen {
    background-color: #399103;
}

.onlineblue {
    background-color: #337ab7;
}

.resultgrey {
    background-color: #6c757d;
}

.slimth {
    line-height: 1;
}
</style>
