import dayjs from "dayjs"

const validationRules = component => {
    component.formErrors = []
    let campaignsQuery = component.$store.getters.getCampaignsQuery
    let startDate = campaignsQuery.start_date
    let endDate = campaignsQuery.end_date
    let storedTargets = JSON.parse(localStorage.getItem("targetGroups"))
    storedTargets = !storedTargets || !storedTargets["getCampaignsQuery"] ? [] : storedTargets["getCampaignsQuery"]
    let daysBetweenDates = dayjs(endDate).diff(startDate, "day") + 1
    let yearsBetweenDates = dayjs(endDate)
        .subtract(1, "d")
        .diff(startDate, "y")
    if (yearsBetweenDates >= 1) {
        component.formErrors.push("Max period är 1 år.")
    }
    if (daysBetweenDates > 1) {
        let storedFilmCodes = localStorage.getItem("savedFilmCodes") ? JSON.parse(localStorage.getItem("savedFilmCodes")) : []
        if (typeof storedFilmCodes["getCampaignsQuery"] !== "undefined") storedFilmCodes = storedFilmCodes["getCampaignsQuery"]
        let selectedChannels = localStorage.getItem("savedChannels") ? JSON.parse(localStorage.getItem("savedChannels")) : {}
        let channelCount = 0
        if (selectedChannels["getCampaignsQuery"] !== undefined) {
            let channelhousesChannels = Object.values(selectedChannels["getCampaignsQuery"])
            for (let i = 0; i < channelhousesChannels.length; i++) {
                channelCount += channelhousesChannels[i].length
            }
        }
        if (storedFilmCodes.length === 0 && !campaignsQuery.buyingAgencies && !campaignsQuery.advertisers && !campaignsQuery.products && (channelCount == 0 || channelCount > 10))
            component.formErrors.push("Var god ange antingen minst 1 filmkod, 1 byrå, 1 annonsör, 1 produkt eller 1-10 kanaler.")
        if (storedTargets.length > 6) component.formErrors.push("Max 6 mått får användas.")
    }
    let monthsBetweenDates = dayjs(endDate).diff(startDate, "M", true)
    if (monthsBetweenDates > 6 && storedTargets.length > 1) {
        component.formErrors.push("Perioden är större än 6 månader och då får max 1 mått användas.")
    } else if (monthsBetweenDates > 3 && storedTargets.length > 2) {
        component.formErrors.push("Perioden är större än 3 måndader och då får max 2 mått användas.")
    } else if (monthsBetweenDates > 1 && storedTargets.length > 4) {
        component.formErrors.push("Perioden är större än 1 månad och då får max 4 mått användas.")
    }
    if (component.formErrors.length > 0) component.formErrors.unshift("Din sökning riskerar att returnera ett väldigt stort dataset.")
}
export { validationRules }
