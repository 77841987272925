<template>
    <div>
        <b-button class="mb-2 mr-sm-2 btn-responsive float-left" size="sm" variant="primary" @click="toggleSelectAll" v-if="false">
            {{ selectAll ? "Avmarkera alla rader" : "Markera alla synliga rader" }}
        </b-button>
        <b-button class="mb-2 btn-responsive float-left" size="sm" variant="danger" @click="excludeSelectedRows" v-if="false">
            Exkludera valda rader
        </b-button>
        <label class="totalRowsStyle float-left">
            Svarsrader: {{ getRowCount }}
        </label>
        <b-table thead-class="stickyfancytableheader" class="tablemainresultclass" hover @row-selected="onRowSelected" select-mode="multi" bordered small striped>
            <template v-slot:cell(plussign)> </template>
            <template v-slot:cell(checkbox)="row">
                <b-form-checkbox v-model="row.rowSelected"></b-form-checkbox>
            </template>
            <template #row-details> </template>
        </b-table>
    </div>
</template>
<script>
export default {
    name: "ResultTableMainCampaign",
    data() {
        return {
            selectAll: false,
        }
    },
    props: {},
    mounted() {},
    computed: {
        getRowCount() {
            return `5/5`
        },
    },

    methods: {
        onRowSelected() {},
        excludeSelectedRows() {},
        toggleSelectAll() {
            this.selectAll = !this.selectAll
        },
    },
}
</script>
<style scoped></style>
