<template>
    <section class="body">
        <div v-if="campaignsInitStatus == 'LOADING'">
            <div v-if="loadingFormData" class="text-center mb-2">
                <b-progress :value="loadingFormDataValue" show-progress class="m-5" />
            </div>
        </div>
        <div class="failedRequest" v-if="campaignsInitStatus == 'FAILED'">
            Kan ej returnera resultat pga ett serverfel.
        </div>
        <div v-if="campaignsInitStatus == 'LOADED'">
            <div v-if="false">
                <code>{{ query }}</code>
                <code>
                    <pre style="text-align: left">{{ JSON.stringify(queryForAPI, null, "    ") }}</pre>
                </code>
            </div>

            <b-form>
                <b-form-row @keyup.enter="onEnter">
                    <FormElement
                        v-for="(fieldData, key) in formFields"
                        :key="key"
                        :fieldData="fieldData"
                        getter="getCampaignsQuery"
                        setter="setCampaignsQuery"
                        :latestDate="$store.getters.getMetadataAdToDate"
                        mutationSetEnddate="setCampaignsEnddate"
                        mutationSetStartdate="setCampaignsStartdate"
                        @onInputChanged="inputChanged"
                        class="mb-3"
                        parentName="campaigns"
                    />
                </b-form-row>
            </b-form>
            <b-form-row class="mb-2 mt-3">
                <p v-html="displayStringForFilter" class="ml-1"></p>
                <b-col class="justify-content-end d-flex align-items-end" cols="12">
                    <CheckboxDropdownColumns getter="getCampaignsQuery" setter="setCampaignsQuery" @onInputChanged="inputChanged" v-bind:fieldData="columnsField" />
                    <div class="ml-2">
                        <b-button type="submit" class="custom-border" variant="light" size="sm" @click="clearAllFields">
                            <b-icon icon="arrow-90deg-left"></b-icon>
                            Rensa sökval
                        </b-button>
                    </div>
                    <div>
                        <b-button type="submit" class="searchbutton ml-2" variant="primary" size="sm" @click="submitSearch" :disabled="disableRunBtn">
                            <b-icon v-if="status != 'LOADING'" icon="arrow-repeat"></b-icon>
                            {{ status == "LOADING" ? "Arbetar..." : "Kör" }}
                        </b-button>
                    </div>
                </b-col>
                <p class="font-weight-bold" v-if="message != ''">{{ message }}</p>
            </b-form-row>
        </div>
        <hr />

        <b-tabs content-class="mt-3" v-model="tabIndex">
            <b-tab title="Lista" active>
                <Spinner v-if="status == 'LOADING'" loadingMsg="Laddar resultat..." />
                <div v-else-if="status == 'EMPTY_RESULT'">
                    Hittade inget för aktuell sökning
                </div>
                <div v-else-if="status == 'LOADED'">
                    <ResultTableMainCampaign />
                </div>
                <div class="failedRequest" v-else-if="status == 'FAILED'">
                    Kan ej returnera resultat pga ett serverfel.
                </div>
                <div v-else-if="status == 'VALIDATION_FAILED'" class="text-danger">
                    <span v-bind:key="index" v-for="(error, index) in formErrors" class="d-block">
                        {{ error }}
                    </span>
                </div>
            </b-tab>
            <b-tab title="Summering" @click="fetchCampaignsSummary">
                <Spinner v-if="statusSummary == 'LOADING'" loadingMsg="Laddar resultat..." />
                <div v-else-if="statusSummary == 'EMPTY_RESULT'">
                    Hittade inget för aktuell sökning
                </div>
                <div v-else-if="statusSummary == 'LOADED'">
                    <ResultTableSumCampaign />
                </div>
                <div class="failedRequest" v-else-if="statusSummary == 'FAILED'">
                    Kan ej returnera resultat pga ett serverfel.
                </div>
                <div v-else-if="statusSummary == 'VALIDATION_FAILED'" class="text-danger">
                    <span v-bind:key="index" v-for="(error, index) in formErrors" class="d-block">
                        {{ error }}
                    </span>
                </div>
            </b-tab>
        </b-tabs>
    </section>
</template>

<script>
import FormElement from "@/components/Shared/FormElement/FormElement"
import Spinner from "@/components/Shared/Spinner/Spinner"
import CheckboxDropdownColumns from "../../Shared/FormElement/CheckboxDropdown/CheckboxDropdownColumns"
import { baseFields } from "./formData"
import { validationRules } from "./campaignValidationRules"
import inputValidation from "@/mixins/inputValidation"
import props from "@/mixins/inputProps"
import { mapState } from "vuex"
import * as Actions from "@/store/modules/campaigns/action-types"
import * as Mutations from "@/store/modules/campaigns/mutation-types"
import * as Helpers from "@/helpers"
import dayjs from "dayjs"
import { download, dateToyymmddFormat, unselectFilteredOutChannels, scrollToBottom } from "../../../api/utils.js"
import ResultTableMainCampaign from "@/components/Layout/Campaigns/ResultTableMainCampaign"
import ResultTableSumCampaign from "@/components/Layout/Campaigns/ResultTableSumCampaign"

export default {
    name: "Campaigns",
    components: {
        FormElement,
        CheckboxDropdownColumns,
        Spinner,
        ResultTableMainCampaign,
        ResultTableSumCampaign,
    },
    mixins: [inputValidation, props],
    data() {
        return {
            loadingFormData: true,
            loadingFormDataValue: 0,
            step: 0,
            formFields: baseFields,
            loadingExportResults: false,
            columnsField: {
                config: { options: [] },
                identifier: "columns",
                label: "Kolumner",
            },
            selectAll: false,
            calledGetChannels: false,
            hasFetchedBuyingAgenciesAdvertisersProducts: false,
            isCampaignDataLoaded: false,
            tabName: "list",
            targetLabel: "",
            message: "",
            formErrors: [],
            targetGroupRequiredText: false,
            tabIndex: 0,
        }
    },
    watch: {
        "$store.getters.getPublishedToDate"(publishedToDate) {
            this.setStartAndEndDate(publishedToDate)
        },
        "$store.getters.getCampaignsQuery.start_date"() {
            let endDate = this.$store.getters.getCampaignsQuery.end_date
            let startDate = this.$store.getters.getCampaignsQuery.start_date
            let validStartDate = dayjs(startDate, "YYYY-MM-DD").format("YYYY-MM-DD") === startDate
            let validEndDate = dayjs(endDate, "YYYY-MM-DD").format("YYYY-MM-DD") === endDate
            if (!validEndDate || !validStartDate) {
                return
            }

            if (startDate > endDate) {
                this.$store.commit(Mutations.setCampaignsEnddate, startDate)
                return
            }
            this.getChannels()
            this.getBuyingAgenciesAdvertisersProducts()
        },
        "$store.getters.getCampaignsQuery.end_date"() {
            let endDate = this.$store.getters.getCampaignsQuery.end_date
            let startDate = this.$store.getters.getCampaignsQuery.start_date

            let validStartDate = dayjs(startDate, "YYYY-MM-DD").format("YYYY-MM-DD") === startDate
            let validEndDate = dayjs(endDate, "YYYY-MM-DD").format("YYYY-MM-DD") === endDate
            if (!validEndDate || !validStartDate) {
                return
            }

            if (startDate > endDate) {
                this.$store.commit(Mutations.setCampaignsStartdate, endDate)
                return
            }
            this.getChannels()
            this.getBuyingAgenciesAdvertisersProducts()
        },
    },
    mounted() {
        this.init()
        let customParseFormat = require("dayjs/plugin/customParseFormat")
        dayjs.extend(customParseFormat)
    },
    updated() {
        let campaignsQuery = this.$store.getters.getCampaignsQuery
        if (campaignsQuery.start_date && campaignsQuery.end_date && !this.isCampaignDataLoaded) {
            this.getBuyingAgenciesAdvertisersProducts()
            this.isCampaignDataLoaded = true
        }
    },
    computed: {
        perPage() {
            return String(this.$store.getters.getCampaignsResults.data.tableRows)
        },
        disableRunBtn() {
            let disableRunBtn = false
            if (this.status == "LOADING" || this.$store.getters.getCampaignsQuery.columns.length == 0) disableRunBtn = true
            return disableRunBtn
        },
        sumVisible() {
            let sumVisible = false
            if (this.results.length > 0) {
                sumVisible = this.results.some(el => el.visible)
            }
            return sumVisible
        },
        displayStringForFilter() {
            const query = this.$store.getters.getCampaignsQuery
            let text = `<b>Datum från:</b> ${query.start_date}, <b>Datum till:</b> ${query.end_date}`
            let channelCount = 0
            let targetGroupCount = 0
            let filmcodeCount = 0
            if (localStorage.getItem("savedFilmCodes")) {
                filmcodeCount = JSON.parse(localStorage.getItem("savedFilmCodes")).getCampaignsQuery.length
                if (filmcodeCount > 0) text += `, <b>Filmkoder:</b> ${filmcodeCount} valda`
            }
            if (query.buyingAgencies) text += `, <b>Byrå:</b> ${query.buyingAgencies.label}`
            if (query.advertisers) text += `, <b>Annonsör:</b> ${query.advertisers.label}`
            if (query.products) text += `, <b>Produkt:</b> ${query.products.label}`
            let selectedChannels = localStorage.getItem("savedChannels") ? JSON.parse(localStorage.getItem("savedChannels")) : []
            if (selectedChannels["getCampaignsQuery"] !== undefined) {
                let channelhousesChannels = Object.values(selectedChannels["getCampaignsQuery"])
                for (let i = 0; i < channelhousesChannels.length; i++) {
                    channelCount += channelhousesChannels[i].length
                }
                if (channelCount > 0) text += `, <b>Kanaler:</b> ${channelCount} valda`
            }
            if (query.spot_types) text += query.spot_types.length > 0 ? `, <b>Filmtyp:</b> ${query.spot_types.length} valda` : ""
            let selectedTargetGroups = localStorage.getItem("targetGroups") ? JSON.parse(localStorage.getItem("targetGroups")) : []
            if (selectedTargetGroups["getCampaignsQuery"] !== undefined) {
                targetGroupCount = selectedTargetGroups["getCampaignsQuery"].length
                text += targetGroupCount > 0 ? `, <b>Målgrupper:</b> ${targetGroupCount} valda` : ""
            }
            return text
        },
        getRowCount() {
            if (this.result.status !== "LOADED") return "0/0"
            else {
                let firstPart
                let perPage = parseInt(this.perPage)
                if (perPage > this.result.data.rows.length) {
                    firstPart = this.result.totalRows
                } else firstPart = perPage

                return `${firstPart}/${this.result.totalRows}`
            }
        },
        results() {
            let summary = this.$store.getters.getCampaignsResults.data.summary
            let rows = this.$store.getters.getCampaignsResults.data.rows.slice(0, this.perPage)
            rows.unshift(...summary.array)
            return rows
        },
        loadingMoreResults() {
            return this.$store.getters.getCampaignsResults.loadingMoreRows
        },
        getMoreResults() {
            let getMoreResults = parseInt(this.perPage) < this.result.totalRows
            return getMoreResults
        },
        queryForAPI() {
            return this.$store.getters.getCampaignsQueryForAPI
        },
        disableSelectAllBtn() {
            const allStarSelected = this.$store.getters.getCampaignsAllWithStarSelected
            if (allStarSelected) return false
            else return true
        },
        disableExcludeBtn() {
            let disableExcludeBtn = true

            if (this.$store.getters.getCampaignsResults.data?.rows.length > 0 && !this.sumVisible) {
                disableExcludeBtn = false
            }
            return disableExcludeBtn
        },
        showSumBtn() {
            let showSumBtn = false
            if (this.$store.getters.getCampaignsResults.status == "LOADED") {
                showSumBtn = this.$store.getters.getCampaignsResults.data.summary.array.length > 0
            }
            return showSumBtn
        },
        result() {
            return this.$store.getters.getCampaignsResults
        },
        selectedResult() {
            return this.$store.getters.getCampaignsSelectedResults
        },
        status() {
            return this.$store.getters.getCampaignsResults.status
        },
        columns() {
            // vanliga kolumner, dvs ej mått
            const attrColumns = this.$store.getters.getCampaignsColumnTypes.map(x => x.column_type)
            // ta bort key-column, den ska inte visas
            // samt sätter align right på alla kolumner som är mått
            return this.addTdClassToLastSumColumns(
                this.$store.getters.getCampaignsResults.data.columns.map(x =>
                    attrColumns.includes(x.column_type)
                        ? x
                        : {
                              ...x,
                              tdAttr: { style: "text-align:right" },
                              thClass: "measure-header",
                          }
                )
            )
        },
        keyColumn() {
            return this.$store.getters.getCampaignsColumnTypes.filter(x => x.key == true)[0]
        },
        // lite state från vuex
        ...mapState({
            campaignsInitStatus: ({ campaigns }) => campaigns.campaignsInitStatus,
            query: ({ campaigns }) => campaigns.campaignsQuery,
        }),
        hiddenColumns() {
            return this.$store.getters.getCampaignsColumnTypes.filter(x => x.hidden == true)
        },
        statusSummary() {
            return ""
        },
    },
    methods: {
        async fetchCampaignsSummary() {
        },
        addTdClassToLastSumColumns(columns) {
            let passedColumnBeforeMeasure = false
            let indexOfColumnBeforeMeasure = 0
            for (let i = 0; i < columns.length; i++) {
                if (columns[i].label == this.$store.getters.getCampaignsResults.data.summary.columnBeforeMeasures || passedColumnBeforeMeasure) {
                    columns[i].tdClass = "tdSummaryDataClass"
                    if (!passedColumnBeforeMeasure) indexOfColumnBeforeMeasure = i
                    passedColumnBeforeMeasure = true
                }
            }
            if (passedColumnBeforeMeasure && indexOfColumnBeforeMeasure > 0) columns[indexOfColumnBeforeMeasure - 1].tdClass = "columnBeforeSummaryData"
            return columns
        },
        clearAllFields() {
            let systemToDate = new Date(this.$store.getters.getMetadataAdToDate)
            this.query.end_date = dateToyymmddFormat(systemToDate.setDate(systemToDate.getDate()))
            this.query.start_date = dateToyymmddFormat(systemToDate.setDate(systemToDate.getDate() - 1))
            this.query.film_code = []
            localStorage.setItem("savedFilmCodes", JSON.stringify({ getCampaignsQuery: [] }))
            this.query.buyingAgencies = null
            this.query.advertisers = null
            this.query.products = null
            //Remove channels from local storage
            var storedChannels = JSON.parse(localStorage.getItem("savedChannels"))
            if (storedChannels != null) {
                storedChannels.getCampaignsQuery = []
                localStorage.setItem("savedChannels", JSON.stringify(storedChannels))
                this.query.channels = []
            }
            //Remove targetgroups from local storage
            var storedTargetGroups = JSON.parse(localStorage.getItem("targetGroups"))
            if (storedTargetGroups != null) {
                storedTargetGroups.getCampaignsQuery = []
                localStorage.setItem("targetGroups", JSON.stringify(storedTargetGroups))
                this.query.target_group = []
            }
            this.query.spot_types = null
            this.query.columns = this.$store.getters.getCampaignsDefaultColumns
        },
        removeKeyColumn(columnArray) {
            return columnArray.filter(x => !(x.column_type == this.keyColumn.column_type && x.key == this.keyColumn.display_name))
        },
        toggleSelectAll() {
            this.selectAll = !this.selectAll

            if (this.selectAll) {
                let filtered = []
                for (let i = 0; i < this.results.length; i++) {
                    if (this.results[i].type === undefined) filtered.push(i)
                }
            }
        },

        async excludeSelectedRows() {
            this.selectAll = false
            let campaignsResults = this.$store.getters.getCampaignsResults
            let summary = campaignsResults.data.summary
            let rows = [...campaignsResults.data.rows]
            rows.unshift(...summary.array)
            let selectedRows = campaignsResults.data.selectedRowsIndex
            let remainingRows = []
            let excludedCampaignsKeys = []
            for (let i = 0; i < rows.length; i++) {
                if (selectedRows.includes(i)) {
                    excludedCampaignsKeys.push(rows[i].SpotKey)
                } else {
                    if (!rows[i].type) {
                        remainingRows.push(rows[i])
                    }
                }
            }
            if (selectedRows.length > 0) {
                this.$store.commit(Mutations.setCampaignsResults, {
                    ...this.$store.getters.getCampaignsResults,
                    loadingMoreRows: true,
                })
                setTimeout(() => this.$store.dispatch(Actions.executeCampaignsQueryMoreExclude, remainingRows), 100)

                this.$store.commit(Mutations.setCampaignsExcludedCampaignIDs, excludedCampaignsKeys)
            }
        },
        async exportResult() {
            this.loadingExportResults = true
            let exportToCsv
            try {
                exportToCsv = await this.$store.dispatch(Actions.executeExportCampaignsQuery)
            } catch (error) {
                this.$bvToast.toast("Det gick inte att exportera", {
                    title: "Serverfel",
                    variant: "danger",
                    solid: true,
                    autoHideDelay: 5000,
                })
                this.loadingExportResults = false
                return
            }

            let fileName = ""
            if (this.$store.getters.getExcelExportFilePreName == undefined || this.$store.getters.getExcelExportFilePreName == "") {
                fileName = `TotalanalysCampaigns_${this.query.start_date}_${this.query.end_date}.csv`
            } else {
                fileName = `${this.$store.getters.getExcelExportFilePreName} TotalanalysCampaigns_${this.query.start_date}_${this.query.end_date}.csv`
            }

            download(exportToCsv, fileName, null)
            this.loadingExportResults = false
        },
        showOrHideSumInTable() {
            this.results.filter(function(s) {
                if (s.type) s.visible = !s.visible
                return s
            })

            if (this.sumVisible && this.$store.getters.getCampaignsExcludedCampaignIDs.length > 0) {
                this.$store.commit(Mutations.setCampaignsSumVisible, true)
                this.submitSearch()
            }
        },
        initListTab() {
            this.tabName = "list"
        },
        getPanelDay(startDate, endDate) {
            let panelDay = new Date((startDate.getTime() + endDate.getTime()) / 2)
            let difference = endDate.getTime() - startDate.getTime()
            let days = Math.ceil(difference / (1000 * 3600 * 24)) + 1
            let isOdd = days % 2
            if (isOdd == 0) {
                panelDay.setDate(panelDay.getDate() + 1)
                return panelDay
            }
            return panelDay
        },
        sortChanged(payload) {
            this.$store.commit(Mutations.setCampaignsResults, {
                ...this.$store.getters.getCampaignsResults,
                loadingMoreRows: true,
            })
            this.selectAll = false
            setTimeout(() => this.$store.dispatch(Actions.changeSortCampaigns, payload), 10)
        },
        getMoreScroll() {
            this.$store.dispatch(Actions.executeCampaignsQueryMoreScroll)
        },
        async init() {
            // många anrop med sideeffects, kör igång med actions
            try {
                await this.$store.commit(Mutations.setCampaignsInitStatus, "LOADING")
                const measures = await this.$store.dispatch(Actions.getCampaignsMeasures)
                this.loadingFormDataValue = 25

                this.formFields.target_group.config.measures = [...measures]
                const spotTypes = await this.$store.dispatch(Actions.getCampaignsSpottypes)
                this.loadingFormDataValue = 50

                this.setStartAndEndDate(this.$store.getters.getPublishedToDate)
                this.$store.commit("setCampaignsSelectedResults", [])
                await this.$store.commit(Mutations.setCampaignsSpottypes, spotTypes)
                this.formFields.spot_types.config.options = spotTypes

                await this.getChannels()
                this.loadingFormDataValue = 75

                const columns = await this.$store.dispatch(Actions.getCampaignsColumnTypes)
                this.loadingFormDataValue = 100
                this.columnsField.config.options = [...columns]
                await this.$store.commit(Mutations.setCampaignsColumnTypes, columns)
                await this.$store.commit(
                    Mutations.setCampaignsDefaultColumns,
                    columns.filter(c => c.default_selection).map(c => c.value)
                )
                let updatedQuery = {
                    ...this.$store.getters["getCampaignsQuery"],
                    ["columns"]: this.$store.getters.getCampaignsDefaultColumns,
                }
                this.$store.commit("setCampaignsQuery", updatedQuery)
                // viktigt med await för att vue ska uppdatera våra komponenter
                // status måste vara = LOADED för att de ska visas och de refreshas då när vi sätter formFields,
                // om de inte visas så refreshas de icke...
                await this.$store.commit(Mutations.setCampaignsInitStatus, "LOADED")
                this.loadingFormData = false
            } catch (error) {
                await this.$store.commit(Mutations.setCampaignsInitStatus, "FAILED")
            }
        },
        async submitSearch() {
            this.tabIndex = 0
            this.selectAll = false
            validationRules(this)
            const hasError = this.validateForm()
            if (hasError) {
                scrollToBottom()
                this.$store.commit(Mutations.setCampaignsResults, {
                    status: "VALIDATION_FAILED",
                })
                if (this.formFields.start_date.hasError || this.formFields.end_date.hasError) this.formErrors.push("Vänligen fyll i konsumtionsperiod från och konsumtionsperiod till korrekt.")
                return
            }
            this.tabName = "list"
            this.$store.commit(Mutations.setResetExcludedCampaignIDs)
            try {
                await this.$store.dispatch(Actions.executeCampaignsQuery)
            } catch (err) {
                this.$store.commit(Mutations.setCampaignsResults, {
                    status: "FAILED",
                })
            }
        },
        selectRow(items) {
            return this.$store.commit("setCampaignsSelectedResults", items)
        },
        setStartAndEndDate(publishedToDate) {
            if (!publishedToDate) return
            let previousDate = this.$store.getters.getCampaignsQuery
            //only set endate to defaultdate when endate is not changed
            if (!previousDate.end_date) this.$store.commit(Mutations.setCampaignsEnddate, this.$store.getters.getMetadataAdToDate)
            if (!previousDate.start_date) {
                let startDate = new Date(this.$store.getters.getMetadataAdToDate)
                this.$store.commit(Mutations.setCampaignsStartdate, dateToyymmddFormat(startDate.setDate(startDate.getDate() - 1)))
            }
        },
        async getChannels() {
            let campaignsQuery = this.$store.getters.getCampaignsQuery
            if (!campaignsQuery.start_date || !campaignsQuery.end_date || this.calledGetChannels) return
            this.calledGetChannels = true
            const channels = await this.$store.dispatch(Actions.getCampaignsChannels)
            await this.$store.commit(Mutations.setCampaignsChannels, channels)
            const newChannels = Helpers.transformToChannelsFromAPIResponse(channels.filter(c => c.is_log))
            let selectedChannels = localStorage.getItem("savedChannels") ? JSON.parse(localStorage.getItem("savedChannels")) : []
            if (selectedChannels["getCampaignsQuery"] !== undefined) {
                unselectFilteredOutChannels(selectedChannels["getCampaignsQuery"], newChannels.config.tabs)
                localStorage.setItem("savedChannels", JSON.stringify(selectedChannels))
            }
            const newFields = {
                ...this.formFields,
                channels: { ...this.formFields.channels, ...newChannels },
            }
            this.formFields = newFields
            this.calledGetChannels = false
        },
        async getBuyingAgenciesAdvertisersProducts() {
            if (!this.hasFetchedBuyingAgenciesAdvertisersProducts) {
                this.hasFetchedBuyingAgenciesAdvertisersProducts = true
                let buyingAgencies = await this.$store.dispatch(Actions.getCampaignsBuyingAgencies)
                let advertisers = await this.$store.dispatch(Actions.getCampaignsAdvertisers)
                let products = await this.$store.dispatch(Actions.getCampaignsProducts)
                this.formFields.buyingAgencies.selectlistitems = buyingAgencies
                this.formFields.advertisers.selectlistitems = advertisers
                this.formFields.products.selectlistitems = products
                this.hasFetchedBuyingAgenciesAdvertisersProducts = false
            }
        },
        onEnter() {
            this.submitSearch()
        },
    },
}
</script>

<style>
.body {
    background-color: white;
    padding: 20px 30px;
}
.totalRowsStyle {
    font-size: 11px;
    margin-left: 10px;
    margin-top: 8px;
}

.searchbutton {
    width: 8vw;
}
.label-text {
    font-weight: 713;
    font-size: 11px;
    color: #495057;
    float: left;
    margin-bottom: 0.35rem;
}
.nav-tabs {
    font-size: 80%;
    font-weight: 400;
    align-items: center;
}
.measure-header {
    text-align: right;
    line-height: 1.1;
}

.failedRequest {
    color: red;
}
</style>
